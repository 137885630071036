<script setup lang="ts">
import { getPrettyCurrentDate } from '@@/bits/date'
import { n__ } from '@@/bits/intl'
import { transformCurrentUrl } from '@@/bits/location'
import { isRegistered } from '@@/bits/user_model'
import OzBadge, { OzBadgeColorScheme, OzBadgeSizePreset } from '@@/library/v4/components/OzBadge.vue'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzRelativeTime from '@@/library/v4/components/OzRelativeTime.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceFreezePadletPanel } from '@@/pinia/surface_freeze_padlet_panel'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import { uniqBy } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const surfaceStore = useSurfaceStore()
const {
  builder,
  backgroundLuminance,
  colorScheme,
  isFrozen,
  isMap,
  skinAccentColorAsRGB,
  skinAccentTextColor,
  wallUpdatedAt,
  isTemplate,
  isMobileLayout,
} = storeToRefs(surfaceStore)

const { showContributorsList } = useSurfaceUserContributorsStore()

const surfacePostsStore = useSurfacePostsStore()
const { postEntities } = storeToRefs(surfacePostsStore)

const surfaceFreezePadletPanelStore = useSurfaceFreezePadletPanel()
const { showSurfaceFreezePadletPanel } = surfaceFreezePadletPanelStore

const surfacePermissionsStore = useSurfacePermissionsStore()
const { canIAdminister } = storeToRefs(surfacePermissionsStore)

const isSkinColorAvailable = computed<boolean>(() => {
  return !!skinAccentColorAsRGB.value && !!skinAccentTextColor.value
})

const bgColorScheme = computed<'light' | 'dark'>(() => {
  return isMap.value && !isMobileLayout.value ? colorScheme.value : backgroundLuminance.value
})

const isBuilderRegistered = computed<boolean>(() => {
  return isRegistered(builder.value)
})

const builderProfileUrl = computed<string>(() => {
  return transformCurrentUrl({}, { path: builder.value.username })
})

const contributorCount = computed<number>(() => {
  const nonBuilderAuthorPosts = postEntities.value.filter((p) => p.author_id !== builder.value.id)
  return uniqBy(nonBuilderAuthorPosts, 'author_id').length
})

const contributorText = computed<string>(() => {
  return n__('%{num} contributor', '%{num} contributors', contributorCount.value, { num: contributorCount.value })
})
</script>

<template>
  <div
    :class="{
      'flex items-center': true,
      'h-3.5': true,
      'font-sans': true,
      'text-dark-text-200': bgColorScheme === 'light',
      'text-light-text-200': bgColorScheme === 'dark',
    }"
  >
    <a
      v-if="isBuilderRegistered"
      :href="builderProfileUrl"
      target="_blank"
      :class="{
        'text-12-14': true,
        'text-dark-text-200 hover-hover:hover:text-dark-text-100 focus-visible:text-dark-text-100 focus-ring:light':
          bgColorScheme === 'light',
        'text-light-text-200 hover-hover:hover:text-light-text-100 focus-visible:text-light-text-100 focus-ring:dark':
          bgColorScheme === 'dark',
        'hover-hover:hover:underline': true,
        truncate: true,
      }"
    >
      {{ builder.name || builder.username }}
    </a>
    <span v-else class="text-12-14">{{ __('Anonymous') }}</span>
    <OzBaseButton
      v-if="contributorCount > 0"
      :class="{
        'contributor-list-button': true,
        'bg-transparent p-0 group': true,
        'text-dark-text-200 hover-hover:hover:text-dark-text-100 focus-visible:text-dark-text-100 focus-ring:light':
          bgColorScheme === 'light',
        'text-light-text-200 hover-hover:hover:text-light-text-100 focus-visible:text-light-text-100 focus-ring:dark':
          bgColorScheme === 'dark',
        'ms-0.75': true,
        'text-12-14': true,
        'shrink-0': true,
      }"
      :title="contributorText"
      :aria-label="__('Show contributors list')"
      @click="showContributorsList"
    >
      +
      <span class="hover-hover:group-hover:underline">{{ contributorCount }}</span>
    </OzBaseButton>
    <div class="ms-0.75 text-12-14 shrink-0">
      <span
        :class="{
          'text-dark-text-300': bgColorScheme === 'light',
          'text-light-text-300': bgColorScheme === 'dark',
        }"
      >
        •
      </span>
      <time v-if="surfaceStore.isScreenshotMode" class="ms-0.75">
        {{ getPrettyCurrentDate() }}
      </time>
      <OzRelativeTime v-else class="ms-0.75" :datetime="wallUpdatedAt" />
    </div>
    <div v-if="isFrozen || isTemplate" class="ms-3 flex gap-2.5">
      <!-- TODO: migrate this template block to SurfaceHeaderFrozenBadge.vue -->
      <template v-if="isFrozen">
        <OzBaseButton
          v-if="canIAdminister"
          :class="[
            'p-0 rounded relative group',
            {
              'focus-badge-button:light': bgColorScheme === 'light',
              'focus-badge-button:dark': bgColorScheme === 'dark',
            },
          ]"
          @click="showSurfaceFreezePadletPanel"
        >
          <OzBadge
            :class="[
              'h-4',

              // Hover
              'hover-hover:group-hover:bg-gradient-to-r',
              {
                'hover-hover:group-hover:from-popover-overlay-light hover-hover:group-hover:to-popover-overlay-light':
                  skinAccentTextColor === 'white',
                'hover-hover:group-hover:from-transparent-button-hover-dark hover-hover:group-hover:to-transparent-button-hover-dark':
                  skinAccentTextColor === 'black',
              },

              // Focus
              'hover-hover:group-focus:bg-gradient-to-r',
              {
                'hover-hover:group-focus:from-popover-overlay-light hover-hover:group-focus:to-popover-overlay-light':
                  skinAccentTextColor === 'white',
                'hover-hover:group-focus:from-transparent-button-hover-dark hover-hover:group-focus:to-transparent-button-hover-dark':
                  skinAccentTextColor === 'black',
              },

              // Active
              {
                'hover-hover:group-active:!bg-grape-500': bgColorScheme === 'light',
                'hover-hover:group-active:!bg-canary-500': bgColorScheme === 'dark',
              },
            ]"
            :style="isSkinColorAvailable ? { backgroundColor: skinAccentColorAsRGB, color: skinAccentTextColor } : {}"
            :text="__('Frozen')"
            :size-preset="OzBadgeSizePreset.Tiny"
            :color-scheme="OzBadgeColorScheme.Bare"
          >
            <template #startAdornment>
              <OzIcon
                name="freeze"
                :size="10"
                :class="[
                  'pe-0.5',
                  {
                    'text-dark-text-200': skinAccentTextColor === 'black',
                    'text-light-text-200': skinAccentTextColor === 'white',
                  },
                ]"
              />
            </template>
          </OzBadge>
        </OzBaseButton>
        <OzBadge
          v-else
          class="h-4"
          :style="isSkinColorAvailable ? { backgroundColor: skinAccentColorAsRGB, color: skinAccentTextColor } : {}"
          :text="__('Frozen')"
          :size-preset="OzBadgeSizePreset.Tiny"
          :color-scheme="OzBadgeColorScheme.Bare"
        >
          <template #startAdornment>
            <OzIcon
              name="freeze"
              :size="10"
              :class="[
                'pe-0.5',
                {
                  'text-dark-text-200': skinAccentTextColor === 'black',
                  'text-light-text-200': skinAccentTextColor === 'white',
                },
              ]"
            />
          </template>
        </OzBadge>
      </template>
      <OzBadge
        v-if="isTemplate"
        data-testid="surfaceTemplateBadge"
        class="h-4"
        :style="isSkinColorAvailable ? { backgroundColor: skinAccentColorAsRGB, color: skinAccentTextColor } : {}"
        :text="__('Template')"
        :size-preset="OzBadgeSizePreset.Tiny"
        :color-scheme="OzBadgeColorScheme.Bare"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.focus-ring\:light:focus-visible,
.focus-ring\:dark:focus-visible {
  position: relative;
  outline: none;
}

.focus-ring\:light:focus-visible::after,
.focus-ring\:dark:focus-visible::after {
  content: '';
  position: absolute;
  top: -0.25rem;
  bottom: -0.25rem;
  left: -0.375rem;
  right: -0.375rem;
  border-radius: 0.5rem;
}

.focus-ring\:light:focus-visible::after {
  outline: 0.1875rem solid #9466e8; // Selection Light
}

.focus-ring\:dark:focus-visible::after {
  outline: 0.1875rem solid #fec006; // Selection Dark
}

.focus-badge-button\:light:focus-visible:after,
.focus-badge-button\:dark:focus-visible:after {
  content: '';
  position: absolute;
  inset: -1px;
  border-radius: 5px;
}

.focus-badge-button\:light:focus-visible::after {
  outline: 1px solid #9466e8; // Selection Light
}

.focus-badge-button\:dark:focus-visible::after {
  outline: 1px solid #fec006; // Selection Dark
}

.contributor-list-button.focus-ring\:light:focus-visible::after,
.contributor-list-button.focus-ring\:dark:focus-visible::after {
  left: -0.1875rem;
}

[dir='rtl'] {
  .contributor-list-button.focus-ring\:light:focus-visible::after,
  .contributor-list-button.focus-ring\:dark:focus-visible::after {
    right: -0.1875rem;
    left: -0.375rem;
  }
}
</style>
