<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { isAppUsing } from '@@/bits/flip'
import { tailwindSurfaceFontClass } from '@@/bits/font'
import { __ } from '@@/bits/intl'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceOnboardingDemoPadletAnimationsStore } from '@@/pinia/surface_onboarding_demo_padlet_animations_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfaceQrCodeStore } from '@@/pinia/surface_qr_code'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import SurfaceBuilder from '@@/vuecomponents/SurfaceBuilder.vue'
import SurfaceDescriptionEditor from '@@/vuecomponents/SurfaceDescriptionEditor.vue'
import SurfaceTitleDescription from '@@/vuecomponents/SurfaceTitleDescription.vue'
import SurfaceTitleEditor from '@@/vuecomponents/SurfaceTitleEditor.vue'
import SurfaceTitleEditorOld from '@@/vuecomponents/SurfaceTitleEditorOld.vue'
import ProcessedImage from '@@/vuecomponents/processed_image.vue'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    xBuilder?: boolean
    xDescription?: boolean
  }>(),
  {
    xBuilder: true,
    xDescription: true,
  },
)
const surfaceStore = useSurfaceStore()
const { title, description, portrait, backgroundLuminance, colorScheme, fontId, isMap, isEmbedded, isFrozen } =
  storeToRefs(surfaceStore)

const { pinnedQrCodeUrl, xPinnedQrCode } = storeToRefs(useSurfaceQrCodeStore())

const surfacePermissionsStore = useSurfacePermissionsStore()
const { canIAdminister } = storeToRefs(surfacePermissionsStore)

const surfaceSettingsStore = useSurfaceSettingsStore()
const { showSettingsPanel } = surfaceSettingsStore

const PORTRAIT_SIZE = 48

const emit = defineEmits(['title-changed', 'description-changed', 'portrait-changed'])

const fontClass = computed<string>(() => {
  return tailwindSurfaceFontClass(fontId.value)
})

watch(title, (): void => {
  emit('title-changed')
})

watch(description, (): void => {
  emit('description-changed')
})

watch(portrait, (): void => {
  emit('portrait-changed')
})

const canModifyWallSettings = computed(() => !isEmbedded.value && !isFrozen.value && canIAdminister.value)

const iconButtonTitle = computed(() => {
  if (canModifyWallSettings.value) {
    return __('Change padlet icon')
  } else if (isFrozen.value) {
    return __('You cannot change the padlet icon because the padlet is frozen')
  } else {
    return __('You do not have permission to change the padlet icon')
  }
})

const tryModify = (settingsSubpanel: SettingsSubpanel): void => {
  if (canModifyWallSettings.value) {
    showSettingsPanel(settingsSubpanel)
  }
}

const tryOpeningIconSettingPanel = (): void => {
  if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) {
    return
  }
  tryModify(SettingsSubpanel.Icon)
}

// Demo padlet logic

const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

const { hasClickedTopicText, xTopicText, pulsateTopicText, xTopicEmojiBackground, xTopicEmoji } = storeToRefs(
  useSurfaceOnboardingDemoPadletAnimationsStore(),
)
const { startTopicAnimation } = useSurfaceOnboardingDemoPadletAnimationsStore()

const surfaceTitleRef = ref<HTMLElement | null>(null)
const surfaceDescriptionComponent = ref<typeof SurfaceTitleDescription | null>(null)

const realBackgroundLuminance = computed(() => {
  if (isMap.value) {
    return colorScheme.value
  }
  return backgroundLuminance.value
})

const handleTitleAndDescriptionClick = (): void => {
  if (!hasClickedTopicText.value) {
    trackEvent('Demo padlet onboarding', 'Clicked on title and description')
    startTopicAnimation()
  }
}
</script>

<template>
  <div
    :class="{
      'flex justify-start': true,
    }"
  >
    <div
      :class="{
        'flex shrink-0 items-center justify-center box-content': true,
        'w-18': !portrait && !xPinnedQrCode && !surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop,
        'w-12 h-12 px-6 pt-5.5': portrait || xPinnedQrCode || surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop,
      }"
    >
      <ImageThumbnail
        v-if="xPinnedQrCode"
        :alt="__('Padlet QR code')"
        :title="__('Padlet QR code')"
        :src="pinnedQrCodeUrl"
        :original-image-height="1280"
        :original-image-width="1280"
        :width="PORTRAIT_SIZE"
        :height="PORTRAIT_SIZE"
      />
      <OzContainedButton
        v-else-if="portrait"
        :color-scheme="OzContainedButtonColorScheme.SecondaryClearDark"
        :dark-mode="realBackgroundLuminance === 'dark'"
        :disabled="!canModifyWallSettings"
        :size-preset="OzContainedButtonSizePreset.H64px"
        :title="iconButtonTitle"
        :aria-label="__('Change padlet icon')"
        aria-describedby="icon-button-description"
        class="p-8 rounded-xl !cursor-default"
        @click="tryOpeningIconSettingPanel"
      >
        <template #icon>
          <ProcessedImage
            :style="{
              backgroundColor:
                surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop && xTopicEmojiBackground
                  ? 'rgba(111, 28, 156, 0.15)'
                  : '',
            }"
            :class="[
              'rounded-lg',
              surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop && 'p-1 transition-all duration-500 ease-in-out',
              'opacity-0' && surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop && !xTopicEmoji,
              'opacity-100' && surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop && xTopicEmoji,
            ]"
            :src="portrait"
            :width="PORTRAIT_SIZE"
            :height="PORTRAIT_SIZE"
            :transforms="{ preset: 'icon', width: PORTRAIT_SIZE, height: PORTRAIT_SIZE }"
          />
        </template>
      </OzContainedButton>
      <span id="icon-button-description" class="sr-only">{{ iconButtonTitle }}</span>
    </div>

    <div
      :class="{
        'max-w-[85%]': true,
        'pb-3': description,
        'pb-6': !description,
        'mt-5.5': !description,
        'mt-3': description,
      }"
    >
      <SurfaceBuilder v-if="props.xBuilder" />
      <template v-if="!surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop">
        <template v-if="canModifyWallSettings">
          <SurfaceTitleEditor
            v-if="isAppUsing('surfaceEditTitleInline')"
            :dark-mode="realBackgroundLuminance === 'dark'"
            :font-class="fontClass"
            :title-changed="emit('title-changed')"
          />
          <SurfaceTitleEditorOld v-else :dark-mode="realBackgroundLuminance === 'dark'" :font-class="fontClass" />
        </template>
        <h1
          v-else
          data-testid="surfaceTitle"
          :class="{
            'mt-[2px]': true,
            [fontClass]: true,
            'text-surface-title font-semibold break-words whitespace-break-spaces': true,
            'text-dark-text-100': realBackgroundLuminance === 'light',
            'text-light-text-100': realBackgroundLuminance === 'dark',
          }"
          v-text="title || __('Untitled padlet')"
        />
        <template v-if="description && xDescription">
          <template v-if="canModifyWallSettings">
            <SurfaceDescriptionEditor
              v-if="isAppUsing('surfaceEditTitleInline')"
              :dark-mode="realBackgroundLuminance === 'dark'"
              :font-id="fontId"
              :description-changed="emit('description-changed')"
            />
            <div v-else class="mt-[2px]">
              <SurfaceTitleDescription
                :is-phrasing-content="true"
                :text="description"
                :font-id="fontId"
                color-scheme="bare"
              />
            </div>
            <!-- <SurfaceDescriptionEditorOld v-else :dark-mode="realBackgroundLuminance === 'dark'" :font-id="fontId" /> -->
          </template>
          <div v-else class="mt-[2px]">
            <SurfaceTitleDescription
              :is-phrasing-content="true"
              :text="description"
              :font-id="fontId"
              color-scheme="bare"
            />
          </div>
        </template>
      </template>
      <!-- New user onboarding: Demo padlet panel -->
      <template v-else>
        <div
          :class="[
            'inline-block px-2 -mx-2 relative duration-500 ease-in-out transition-all rounded-xl overflow-hidden pe-10',
            pulsateTopicText && 'animate-pulse hover:opacity-75 cursor-pointer',
          ]"
          :style="{
            backgroundColor: pulsateTopicText ? 'rgba(111, 28, 156, 0.15)' : '',
          }"
          @click="handleTitleAndDescriptionClick"
          @keydown="handleTitleAndDescriptionClick"
        >
          <!-- Background Container -->
          <div
            :class="[
              'absolute z-10 inset-0 inline-block px-2 transition-all -mx-2 duration-500 ease-in-out rounded-xl overflow-hidden',
              pulsateTopicText && 'animate-pulse',
            ]"
          ></div>
          <!-- Text Content -->
          <div class="relative">
            <h1
              ref="surfaceTitleRef"
              data-testid="surfaceTitle"
              :class="[
                'mt-0.5',
                fontClass,
                'text-surface-title font-semibold break-words whitespace-break-spaces',
                realBackgroundLuminance === 'light' ? 'text-dark-text-100' : 'text-light-text-100',
                'ps-2 -ms-2 pe-10 rounded-xl inline-block z-20 transition-colors duration-500 ease-in-out',
                !xTopicText && 'text-transparent',
              ]"
              v-text="title"
            />
            <SurfaceTitleDescription
              ref="surfaceDescriptionComponent"
              :text="description"
              :font-id="fontId"
              :color-scheme="realBackgroundLuminance"
              :class="{
                'pb-7 ps-2 -ms-2 rounded-xl overflow-hidden block z-20': true,
                'transition-colors duration-500 ease-in-out': true,
                'text-transparent': !xTopicText,
                'text-dark-text-200': xTopicText && realBackgroundLuminance === 'light',
                'text-light-text-200': xTopicText && realBackgroundLuminance === 'dark',
              }"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
