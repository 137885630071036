<script setup lang="ts">
import device from '@@/bits/device'
import { defineAsyncComponent } from '@@/bits/vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContributingStatusStore } from '@@/pinia/surface_contributing_status'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import AvatarImage from '@@/vuecomponents/avatar_image.vue'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, onMounted, watch } from 'vue'

const SurfaceActivitySvg = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceActivitySvg.vue'))

const surfaceStore = useSurfaceStore()
const surfaceDraftsStore = useSurfaceDraftsStore()
const surfaceContributingStatusStore = useSurfaceContributingStatusStore()

const { backgroundColor, backgroundLuminance, isSubmissionRequest } = storeToRefs(surfaceStore)
const { isAnyDraftActive } = storeToRefs(surfaceDraftsStore)
const { activeContributorsList, numberOfAdditionalContributors, xAdditionalContributors, xActivityIndicator } =
  storeToRefs(surfaceContributingStatusStore)
const { clearContributingStatus, updateContributingStatus } = surfaceContributingStatusStore

const isDesktop = device.desktop

watch(isAnyDraftActive, (): void => {
  updateContributingStatus()
})

const clearContributingStatusOnPageClosed = (): void => {
  clearContributingStatus()
}

const clearContributingStatusOnVisibilityChanged = (): void => {
  if (document.visibilityState === 'visible') {
    updateContributingStatus()
  }
  if (document.visibilityState === 'hidden') {
    clearContributingStatus()
  }
}

const clearContributingStatusOnBlur = (): void => {
  clearContributingStatus()
}

const setContributingStatusOnFocus = (): void => {
  updateContributingStatus()
}

onMounted((): void => {
  window.addEventListener('beforeunload', clearContributingStatusOnPageClosed)
  window.addEventListener('blur', clearContributingStatusOnBlur)
  window.addEventListener('focus', setContributingStatusOnFocus)
  window.addEventListener('visibilitychange', clearContributingStatusOnVisibilityChanged)
})

onBeforeUnmount((): void => {
  window.removeEventListener('beforeunload', clearContributingStatusOnPageClosed)
  window.removeEventListener('blur', clearContributingStatusOnBlur)
  window.removeEventListener('focus', setContributingStatusOnFocus)
  window.removeEventListener('visibilitychange', clearContributingStatusOnVisibilityChanged)
})
</script>

<template>
  <transition
    enter-active-class="transition-opacity duration-150 ease-in"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-150 ease-out"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="xActivityIndicator && !isSubmissionRequest"
      :style="{ color: backgroundColor }"
      class="flex flex-row"
      data-testid="surfaceActivityIndicator"
    >
      <template v-if="isDesktop">
        <div
          v-for="(user, index) in activeContributorsList"
          :key="'user-' + index"
          :class="[
            {
              'w-5.5': index === activeContributorsList.length - 1,
              'w-4.75': index !== activeContributorsList.length - 1,
            },
            'h-5.5',
            'relative',
            'hidden',
            'sm:flex',
          ]"
        >
          <avatar-image
            :user="user"
            :width="20"
            :height="20"
            :class="['absolute', 'border-current', 'border-solid', 'border', 'top-0', 'left-0']"
            :style="{
              zIndex: activeContributorsList.length - index,
            } /* one to the left is in front of the one to the right*/"
          />
        </div>
        <div
          v-if="xAdditionalContributors"
          :class="[
            'hidden',
            'sm:block',
            'ml-1.5',
            'mt-px',
            'text-14',
            {
              'text-light-text-300': backgroundLuminance === 'dark',
              'text-dark-text-300': backgroundLuminance === 'light',
            },
          ]"
        >
          +{{ numberOfAdditionalContributors }}
        </div>
      </template>

      <SurfaceActivitySvg
        :class="isDesktop && 'sm:ml-1.5'"
        width="20"
        height="20"
        :color-scheme="backgroundLuminance === 'dark' ? 'light' : 'dark'"
      />
    </div>
  </transition>
</template>
