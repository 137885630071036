<script setup lang="ts">
import { sanitizedLink as link } from '@@/bits/autolinker'
import { tailwindSurfaceFontClass } from '@@/bits/font'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  // Whether the component should be rendered as a phrasing content element - i.e. <span>.
  // Set this to true if the component will be included within a context where it needs to be
  // rendered as a phrasing content element - i.e. in a button.
  isPhrasingContent: {
    required: false,
    type: Boolean,
    default: false,
  },
  fontId: {
    required: true,
    type: Number,
  },
  text: {
    required: true,
    type: String,
  },
  colorScheme: {
    type: String,
    default: 'light',
    validator: (value) => ['light', 'dark', 'bare'].includes(value as string),
  },
})

const fontClass = computed(() => tailwindSurfaceFontClass(props.fontId))
const hyperlinkedText = ref<string>(props.text)

watch(
  () => props.text,
  async (text) => (hyperlinkedText.value = await link(text, { className: 'break-all' })),
  { immediate: true },
)
</script>

<template>
  <component
    :is="isPhrasingContent ? 'span' : 'h2'"
    :class="[
      fontClass,
      'mt-[2px]',
      'text-body-small',
      'break-word-anywhere',
      'whitespace-break-spaces',
      {
        'text-dark-text-200': colorScheme === 'light',
        'text-light-text-200': colorScheme === 'dark',
      },
    ]"
    data-testid="surfaceDescription"
    v-html="hyperlinkedText"
  />
</template>

<style lang="postcss" scoped>
:deep(a) {
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
</style>
