<script setup lang="ts">
import { __ } from '@@/bits/intl'
import { DESCRIPTION_CHAR_LIMIT } from '@@/bits/surface_settings_helper'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzMultilineInput from '@@/library/v4/components/OzMultilineInput.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import SurfaceTitleDescription from '@@/vuecomponents/SurfaceTitleDescription.vue'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, nextTick, ref, watch } from 'vue'

defineProps<{
  darkMode: boolean
  fontId: number
}>()

const emit = defineEmits(['description-changed'])

const surfaceStore = useSurfaceStore()
const { description, isSectionBreakout } = storeToRefs(surfaceStore)

const surfaceSettingsStore = useSurfaceSettingsStore()
const {
  asyncSaveSettings,
  setIsDescriptionClicked,
  showSettingsPanel,
  startWallAttributesPreview,
  stopWallAttributesPreview,
  updatePreviewAttributes,
} = surfaceSettingsStore

const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
const { isContainerSmallerThanTabletLandscape } = storeToRefs(surfaceContainerSizeStore)

const surfaceOnboardingDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
const { isDemoPadletPanelDesktop } = storeToRefs(surfaceOnboardingDemoPadletPanelStore)

const isInputFocused = ref(false)
const isBeingEditedInline = ref(false)
const currentDescription = ref(description.value ?? '')
const previousDescription = ref(description.value ?? '')

const rootEl = ref<HTMLElement>() as Ref<HTMLElement>
const descriptionInput = ref<InstanceType<typeof OzMultilineInput>>()

async function saveCurrentDescriptionIfValid(): Promise<void> {
  if (!isDescriptionChanged.value) return
  if (!isCurrentDescriptionValid.value) {
    currentDescription.value = previousDescription.value
    return
  }

  startWallAttributesPreview()
  updatePreviewAttributes({ description: currentDescription.value })
  await asyncSaveSettings()
  stopWallAttributesPreview()
  previousDescription.value = currentDescription.value
  emit('description-changed')
}

function finishEditing(): void {
  saveCurrentDescriptionIfValid()
  isBeingEditedInline.value = false
}

/**
 * Finish editing and save update if the user clicks or tabs out of the description input.
 */
function finishEditingIfFocusTargetIsOutside(e: FocusEvent): void {
  // When OzMultilineInput loses focus, we don't want to emit `finish-editing` too early
  // because it may be a Tab press to focus on the Done button. To prevent this, we
  // check `e.relatedTarget` to see if it's an element inside the description or not.
  // If it's not, then we can safely emit the event.
  if (!rootEl.value) return
  if (rootEl.value.contains(e.relatedTarget as Node | null)) return
  finishEditing()
}

function cancelEditing(): void {
  currentDescription.value = previousDescription.value
  isBeingEditedInline.value = false
}

function onDescriptionChange(e: Event): void {
  const newDescription = (e.target as HTMLTextAreaElement).value
  currentDescription.value = newDescription
}

function selectDescriptionInput(): void {
  descriptionInput.value?.select()
}
// Select input if in edit mode
watch(
  isBeingEditedInline,
  (newVal) => {
    if (newVal) {
      nextTick(() => {
        selectDescriptionInput()
      })
    }
  },
  { immediate: true },
)

const isDescriptionChanged = computed(() => {
  const trimmedDescription = currentDescription.value.trim()
  return trimmedDescription !== previousDescription.value
})

const isCurrentDescriptionAtLimit = computed(() => {
  const trimmedDescriptionLength = currentDescription.value.trim().length
  return trimmedDescriptionLength >= DESCRIPTION_CHAR_LIMIT
})

/**
 * When isCurrentDescriptionAtLimit is true, the input is actually still valid,
 * but still set isInputValid to false to show the validation message,
 * which will say "Character limit reached"
 */
const isInputValid = computed(() => !isCurrentDescriptionAtLimit.value)

const isCurrentDescriptionTooLong = computed(() => {
  const trimmedDescriptionLength = currentDescription.value.trim().length
  return trimmedDescriptionLength > DESCRIPTION_CHAR_LIMIT
})

/**
 * It should not be possible for isCurrentDescriptionTooLong to be true,
 * because the input has a max length which prevents the user from exceeding the limit.
 * However, we have a fallback in case the max length is somehow removed.
 */
const isCurrentDescriptionValid = computed(() => !isCurrentDescriptionTooLong.value)

/**
 * Note that you can set the description to empty.
 */
const validationMessage = computed(() => {
  if (isCurrentDescriptionAtLimit.value) {
    return __('Character limit reached')
  } else {
    return ''
  }
})

/**
 * Similar to canEditSectionNameInline
 */
const canEditInline = computed<boolean>(() => {
  return !isContainerSmallerThanTabletLandscape.value && !isDemoPadletPanelDesktop.value && !isSectionBreakout.value
})

const handleDescriptionClick = (): void => {
  if (canEditInline.value) {
    currentDescription.value = description.value ?? ''
    isBeingEditedInline.value = true
  } else {
    setIsDescriptionClicked(true)
    showSettingsPanel(SettingsSubpanel.Main)
  }
}

const isMoreThanOneLine = computed<boolean>(() => {
  const oneLineHeight = 24 // 20 + 4: the line height of the input is 20px, and the top and bottom border of the input added together is 4px
  const height = descriptionInput.value?.height

  if (!height) return false
  return height > oneLineHeight
})
</script>

<template>
  <div ref="rootEl" class="flex -ms-2 mt-[0.5px]">
    <!-- Inline editing mode -->
    <template v-if="isBeingEditedInline">
      <p id="description-input-description" class="sr-only">
        {{ __('The current padlet description is %{description}', { description: currentDescription }) }}
      </p>
      <!-- 
      Add -ms-0.5 to the input to align it with the the rest of the header.
      I can't add it to the parent's -ms-2 because that would move the 
      non-editing mode display button too far to the left.
       -->
      <OzMultilineInput
        ref="descriptionInput"
        :value="currentDescription"
        :border="'full'"
        :font-size="14"
        :is-valid="isInputValid"
        :validation-message="validationMessage"
        test-id="surfaceWallDescriptionInlineInput"
        :max-length="DESCRIPTION_CHAR_LIMIT"
        :class="[
          'flex',
          'items-center',
          '-ms-0.5',
          'px-2',
          isMoreThanOneLine ? '!rounded-xl' : '!rounded-lg',
          isInputFocused
            ? {
                'bg-light-ui-100': darkMode === false,
                'bg-dark-ui-100': darkMode === true,
              }
            : {
                'bg-grey-100': darkMode === false,
                'bg-grey-850': darkMode === true,
              },
        ]"
        :cols="currentDescription.length + 1"
        :padding-class="'p-0'"
        :input-classes="'min-w-[280px] break-words whitespace-break-spaces border-none'"
        aria-describedby="description-input-description"
        :aria-label="__('Padlet description')"
        :dark-mode="darkMode === true"
        @focus="isInputFocused = true"
        @focusout="isInputFocused = false"
        @blur="finishEditingIfFocusTargetIsOutside"
        @enter="finishEditing"
        @escape="cancelEditing"
        @input="onDescriptionChange"
        @paste.stop
      />
    </template>
    <!-- Non-editing mode -->
    <template v-else>
      <p id="description-button-description" class="sr-only">{{ description }}</p>
      <OzContainedButton
        :auto-height="true"
        :color-scheme="OzContainedButtonColorScheme.SecondaryClearDark"
        :dark-mode="darkMode"
        :size-preset="OzContainedButtonSizePreset.Bare"
        :title="__('Change padlet description')"
        :aria-label="__('Change padlet description')"
        aria-describedby="description-button-description"
        text-align="start"
        class="py-0.5 ps-2 pe-7 rounded-xl focus-visible:ring !cursor-default select-auto"
        @click="handleDescriptionClick"
      >
        <!-- After adding the ps-2, I added a -ms-2 so that the description text is still aligned with the rest of the header. -->
        <!-- Use bare color scheme because it only controls text color, and in this case text color will be controlled by the parent button -->
        <SurfaceTitleDescription
          :is-phrasing-content="true"
          :text="description"
          :font-id="fontId"
          color-scheme="bare"
        />
      </OzContainedButton>
    </template>
    <!-- 
      Always render this button, but make it invisible if not in inline editing mode.
      This is to keep the button in the DOM so that it does not cause a layout shift
      when switching between editing and non-editing mode.
     -->
    <OzPlainButton
      :class="[
        !isBeingEditedInline && 'invisible',
        'shrink-0',
        'h-6',
        'text-body-small',
        'ms-1',
        'px-2',
        'rounded-xl',
        'focus-visible:ring-2',
      ]"
      :inert="!isBeingEditedInline"
      :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
      :size-preset="OzPlainButtonSizePreset.Bare"
      :dark-mode="darkMode === true"
      :text="__('Done')"
      data-testid="surfaceWallDescriptionInlineInputDoneButton"
      @blur="finishEditingIfFocusTargetIsOutside"
      @click.stop="finishEditing"
    />
  </div>
</template>
