<script lang="ts" setup>
import { appLink } from '@@/bits/app_link'
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import { navigateTo } from '@@/bits/location'
import { isRegistered } from '@@/bits/user_model'
import { defineAsyncComponent } from '@@/bits/vue'
import { checkIfUserIsInZoomCollaborateMode } from '@@/bits/zoom'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzPadletLogo, {
  OzPadletLogoColorScheme,
  OzPadletLogoSizePreset,
} from '@@/library/v4/components/OzPadletLogo.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import SurfaceActivityIndicator from '@@/vuecomponents/SurfaceActivityIndicator.vue'
import SurfaceHeaderMobile from '@@/vuecomponents/SurfaceHeaderMobile.vue'
import SurfacePadletLogoInScreenshotMode from '@@/vuecomponents/SurfacePadletLogoInScreenshotMode.vue'
import SurfaceTitle from '@@/vuecomponents/SurfaceTitle.vue'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

const SurfaceNav = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceNav.vue'))
const SurfaceSearchBar = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSearchBar.vue'))
const SurfaceTitleOld = defineAsyncComponent(() => import('@@/vuecomponents/surface_title.vue'))

const surfaceStore = useSurfaceStore()
const {
  xHeader,
  isEmbedded,
  searchBarTop,
  isWhiteboard,
  isMap,
  backgroundColor,
  headerColor,
  backgroundLuminance,
  colorScheme,
  homeUrl,
  hasSidePanelOutsideSurface,
  isReadOnly,
  isSubmissionRequest,
  isDesktopLayout,
  isMobileLayout,
  isUserHostingZoomCollaboration,
  user,
  skinPrimaryVariantColorAsRGB,
  isSkinColorAvailable,
  isNativeTenant,
  isScreenshotMode,
} = storeToRefs(surfaceStore)

const { demoPadletPanelVisibility, isDemoPadletPanelDesktop } = storeToRefs(useSurfaceOnboardingDemoPadletPanelStore())

const surfaceGuestStore = useSurfaceGuestStore()

const amIRegistered = computed(() => user.value.registered)
const hasDarkBackgroundLuminance = computed(() => {
  if (isMap.value) {
    return colorScheme.value !== 'light'
  }

  return backgroundLuminance.value !== 'light'
})
const realBackgroundLuminance = computed(() => {
  if (isMap.value) {
    return colorScheme.value
  }
  return backgroundLuminance.value
})

const isUserRegistered = computed(() => {
  return isRegistered(user.value)
})

// Obfuscate the app link
function openAppLink() {
  navigateTo(appLink)
}
/**
 * ZOOM
 */
const isUserInZoomCollaborationMode = ref(false)
const isZoomCollaborationGuest = computed(
  () => isUserInZoomCollaborationMode.value === true && isUserHostingZoomCollaboration.value === false,
)
onMounted(async () => {
  // Don't prompt for notifications if the user is not registered and the app is not the electron app
  if (amIRegistered.value && !device.electronApp) {
    surfaceStore.checkAndSubscribeToPushNotifications()
  }

  isUserInZoomCollaborationMode.value = await checkIfUserIsInZoomCollaborateMode()
})

const updateHeaderHeight = () => {
  if (isDesktopLayout.value) {
    nextTick(() => {
      const headerEl = surfaceHeader.value
      if (!headerEl) return
      surfaceStore.resizeHeader({ height: headerEl.getBoundingClientRect().height })
    })
  }
}

const surfaceHeaderMobile = ref<InstanceType<typeof SurfaceHeaderMobile>>()
const collapse = (): void => {
  if (!surfaceHeaderMobile.value) return
  surfaceHeaderMobile.value.collapse()
}

const surfaceHeaderDesktop = ref<HTMLDivElement>()
const surfaceHeaderEmbedded = ref<HTMLDivElement>()
const surfaceHeader = computed(
  () =>
    (surfaceHeaderMobile.value?.$el || surfaceHeaderDesktop.value || surfaceHeaderEmbedded.value) as
      | HTMLElement
      | undefined,
)

watch(
  surfaceHeader,
  () => {
    updateHeaderHeight()
  },
  { immediate: true },
)

defineExpose({
  collapse,
})
</script>

<template>
  <SurfaceHeaderMobile v-if="isMobileLayout && !isEmbedded" id="surface-header" ref="surfaceHeaderMobile" />
  <div
    v-else-if="isDesktopLayout && !isEmbedded"
    id="surface-header"
    ref="surfaceHeaderDesktop"
    data-testid="surfaceHeader"
    :dir="dir()"
    :class="['app-bar', 'surface-header', 'relative']"
    :data-luminance="realBackgroundLuminance"
    :style="{ backgroundColor: headerColor }"
  >
    <SurfacePadletLogoInScreenshotMode v-if="isScreenshotMode" />
    <div v-else :class="['absolute flex flex-row top-4.5 start-5 gap-x-2']">
      <OzPadletLogo
        v-if="xHeader && !device.electronApp && !isZoomCollaborationGuest"
        :href="homeUrl"
        :dark-mode="hasDarkBackgroundLuminance"
        :size-preset="OzPadletLogoSizePreset.W48px"
        :color-scheme="OzPadletLogoColorScheme.Text300"
        data-testid="padletLogo"
      />
      <OzContainedButton
        v-if="
          isAppUsing('openInAppBanner') && !device.app && (device.ios || device.android) && !isDemoPadletPanelDesktop
        "
        class="max-w-39 truncate"
        :style="isSkinColorAvailable ? { backgroundColor: skinPrimaryVariantColorAsRGB } : {}"
        :aria-label="__('Open in Padlet app')"
        :text="__('Open in app')"
        :color-scheme="OzContainedButtonColorScheme.Transparent"
        :size-preset="OzContainedButtonSizePreset.H24px"
        :dark-mode="realBackgroundLuminance === 'dark'"
        data-testid="surfaceOpenInAppButton"
        data-pepin-id="surface-header-tablet-open-in-app"
        data-pepin-trigger="click"
        @click="openAppLink"
      />
    </div>
    <SurfaceActivityIndicator
      :class="[
        'absolute top-4.5',
        // We don't know the width of this component, so to horizontally
        // center it, we set its start to 50% of its parent, and translate
        // it 50% to the opposite direction.
        'start-1/2 -translate-x-1/2',
      ]"
    />

    <SurfaceTitle
      class="mt-9"
      @title-changed="updateHeaderHeight"
      @description-changed="updateHeaderHeight"
      @portrait-changed="updateHeaderHeight"
    />

    <!-- SurfaceHeader's size will change depending on whether the vertical scrollbar appears -->
    <!-- or not. Since we want to keep the search bar's position fixed regardless of that, we -->
    <!-- use `position: fixed` and update its `top` when we scroll. See `surface_container.vue` -->
    <!-- `handlePageScroll` method. -->
    <!-- Distance of search bar from  -->
    <!-- 488px because size of panel is 400px when smaller than tablet portrait -->
    <!-- 568px because size of panel is 480px when smaller than tablet portrait -->
    <div
      v-if="!isReadOnly && !isSubmissionRequest"
      :class="[
        'fixed z-search-bar flex items-center h-10',
        hasSidePanelOutsideSurface ? 'end-22 @desktop/surface-container:end-42' : 'end-22',
      ]"
      :style="{
        top: `${searchBarTop}px`,
      }"
    >
      <SurfaceSearchBar v-if="!isWhiteboard && demoPadletPanelVisibility.showSurfaceSearchBar" />
      <OzContainedButton
        v-if="isAppUsing('signUpSurface') && !isUserRegistered"
        :class="[
          'ms-4',

          // Base
          {
            'bg-[rgba(255,255,255,0.32)]': backgroundLuminance === 'light',
            'bg-[rgba(0,0,0,0.24)]': backgroundLuminance === 'dark',
          },
          'backdrop-blur-sm',
          {
            'text-dark-text-200': backgroundLuminance === 'light',
            'text-light-text-200': backgroundLuminance === 'dark',
          },

          // Hover
          {
            'hover-hover:hover:bg-light-ui-100': backgroundLuminance === 'light',
            'hover-hover:hover:bg-dark-ui-100': backgroundLuminance === 'dark',
          },
          {
            'hover-hover:hover:text-dark-text-100': backgroundLuminance === 'light',
            'hover-hover:hover:text-light-text-100': backgroundLuminance === 'dark',
          },
        ]"
        :href="isNativeTenant ? surfaceGuestStore.signupUrl : surfaceGuestStore.loginUrl"
        :data-pepin-id="isNativeTenant ? 'signup-cta-on-surface' : 'login-cta-on-surface'"
        :color-scheme="OzContainedButtonColorScheme.Bare"
        :size-preset="OzContainedButtonSizePreset.H32px"
        :dark-mode="backgroundLuminance === 'dark'"
        :text="isNativeTenant ? __('Sign up') : __('Log in')"
      />
    </div>
  </div>
  <div
    v-else
    id="surface-header"
    ref="surfaceHeaderEmbedded"
    :class="['app-bar', 'surface-header']"
    :data-luminance="realBackgroundLuminance"
    :style="{ backgroundColor: headerColor }"
  >
    <div class="rubber-band-scroll-padder" :style="{ backgroundColor: backgroundColor }" />
    <SurfaceNav v-if="xHeader" />
    <SurfaceTitleOld @title-changed="updateHeaderHeight" @description-changed="updateHeaderHeight" />
  </div>
</template>
