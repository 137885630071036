<script setup lang="ts">
import { __ } from '@@/bits/intl'
import { TITLE_CHAR_LIMIT } from '@@/bits/surface_settings_helper'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzMultilineInput from '@@/library/v4/components/OzMultilineInput.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, nextTick, ref, watch } from 'vue'

defineProps<{
  darkMode: boolean
  fontClass: string
}>()

const emit = defineEmits(['title-changed'])

const surfaceStore = useSurfaceStore()
const { title, isSectionBreakout } = storeToRefs(surfaceStore)

const surfaceSettingsStore = useSurfaceSettingsStore()
const {
  asyncSaveSettings,
  setIsTitleClicked,
  showSettingsPanel,
  startWallAttributesPreview,
  stopWallAttributesPreview,
  updatePreviewAttributes,
} = surfaceSettingsStore

const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
const { isContainerSmallerThanTabletLandscape } = storeToRefs(surfaceContainerSizeStore)

const surfaceOnboardingDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
const { isDemoPadletPanelDesktop } = storeToRefs(surfaceOnboardingDemoPadletPanelStore)

const isInputFocused = ref(false)
const isBeingEditedInline = ref(false)
const currentTitle = ref(title.value)
const previousTitle = ref(title.value)

const rootEl = ref<HTMLElement>() as Ref<HTMLElement>
const titleInput = ref<InstanceType<typeof OzMultilineInput>>()

async function saveCurrentTitleIfValid(): Promise<void> {
  if (!isTitleChanged.value) return
  if (!isCurrentTitleValid.value) {
    currentTitle.value = previousTitle.value
    return
  }

  startWallAttributesPreview()
  updatePreviewAttributes({ title: currentTitle.value })
  await asyncSaveSettings()
  stopWallAttributesPreview()
  previousTitle.value = currentTitle.value
  emit('title-changed')
}

function finishEditing(): void {
  saveCurrentTitleIfValid()
  isBeingEditedInline.value = false
}

/**
 * Finish editing and save update if the user clicks or tabs out of the title input.
 */
function finishEditingIfFocusTargetIsOutside(e: FocusEvent): void {
  // When OzMultilineInput loses focus, we don't want to emit `finish-editing` too early
  // because it may be a Tab press to focus on the Done button. To prevent this, we
  // check `e.relatedTarget` to see if it's an element inside the title or not.
  // If it's not, then we can safely emit the event.
  if (!rootEl.value) return
  if (rootEl.value.contains(e.relatedTarget as Node | null)) return
  finishEditing()
}

function cancelEditing(): void {
  currentTitle.value = previousTitle.value
  isBeingEditedInline.value = false
}

function onTitleChange(e: Event): void {
  const newTitle = (e.target as HTMLTextAreaElement).value
  currentTitle.value = newTitle
}

function selectTitleInput(): void {
  titleInput.value?.select()
}

// Select input if in edit mode
watch(
  isBeingEditedInline,
  (newVal) => {
    if (newVal) {
      nextTick(() => {
        selectTitleInput()
      })
    }
  },
  { immediate: true },
)

const isTitleChanged = computed(() => {
  const trimmedTitle = currentTitle.value.trim()
  return trimmedTitle !== previousTitle.value
})

const isCurrentTitleAtLimit = computed(() => {
  const trimmedTitleLength = currentTitle.value.trim().length
  return trimmedTitleLength >= TITLE_CHAR_LIMIT
})

const isCurrentTitleEmpty = computed(() => {
  const trimmedTitleLength = currentTitle.value.trim().length
  return trimmedTitleLength === 0
})

/**
 * When isCurrentTitleAtLimit is true, the input is actually still valid,
 * but still set isInputValid to false to show the validation message,
 * which will say "Character limit reached"
 */
const isInputValid = computed(() => !isCurrentTitleAtLimit.value && !isCurrentTitleEmpty.value)

const isCurrentTitleTooLong = computed(() => {
  const trimmedTitleLength = currentTitle.value.trim().length
  return trimmedTitleLength > TITLE_CHAR_LIMIT
})

/**
 * It should not be possible for isCurrentTitleTooLong to be true,
 * because the input has a max length which prevents the user from exceeding the limit.
 * However, we have a fallback in case the max length is somehow removed.
 */
const isCurrentTitleValid = computed(() => !isCurrentTitleTooLong.value && !isCurrentTitleEmpty.value)

const validationMessage = computed(() => {
  if (isCurrentTitleAtLimit.value) {
    return __('Character limit reached')
  } else if (isCurrentTitleEmpty.value) {
    return __('Please enter a title')
  } else {
    return ''
  }
})

/**
 * Similar to canEditSectionNameInline
 */
const canEditInline = computed<boolean>(() => {
  return !isContainerSmallerThanTabletLandscape.value && !isDemoPadletPanelDesktop.value && !isSectionBreakout.value
})

const handleTitleClick = (): void => {
  if (canEditInline.value) {
    currentTitle.value = title.value
    isBeingEditedInline.value = true
  } else {
    setIsTitleClicked(true)
    showSettingsPanel(SettingsSubpanel.Main)
  }
}
</script>

<template>
  <div ref="rootEl" class="flex -ms-2 mt-[0.5px]">
    <!-- Inline editing mode -->
    <template v-if="isBeingEditedInline">
      <p id="title-input-description" class="sr-only">
        {{ __('The current padlet title is %{title}', { title: currentTitle }) }}
      </p>
      <!-- 
      Add -ms-0.5 to the input to align it with the the rest of the header.
      I can't add it to the parent's -ms-2 because that would move the 
      non-editing mode display button too far to the left.
       -->
      <OzMultilineInput
        ref="titleInput"
        :value="currentTitle"
        :border="'full'"
        :is-valid="isInputValid"
        :validation-message="validationMessage"
        test-id="surfaceWallTitleInlineInput"
        :max-length="TITLE_CHAR_LIMIT"
        :class="[
          'flex',
          'items-center',
          '-ms-0.5',
          'px-2',
          '!rounded-xl',
          isInputFocused
            ? {
                'bg-light-ui-100': darkMode === false,
                'bg-dark-ui-100': darkMode === true,
              }
            : {
                'bg-grey-100': darkMode === false,
                'bg-grey-850': darkMode === true,
              },
        ]"
        :cols="currentTitle.length + 1"
        :padding-class="'p-0'"
        :input-classes="'min-w-[280px] font-semibold text-surface-title break-words whitespace-break-spaces border-none'"
        aria-describedby="title-input-description"
        :aria-label="__('Padlet title')"
        :dark-mode="darkMode === true"
        @focus="isInputFocused = true"
        @focusout="isInputFocused = false"
        @blur="finishEditingIfFocusTargetIsOutside"
        @enter="finishEditing"
        @escape="cancelEditing"
        @input="onTitleChange"
        @paste.stop
      />
    </template>
    <!-- Non-editing mode -->
    <template v-else>
      <h1 id="title-button-description" class="sr-only">{{ title }}</h1>
      <!-- After adding the px-2, I added a -ms-2 so that the title text is still aligned with the rest of the header. -->
      <OzBaseButton
        :class="{
          'py-0.5': true,
          'px-2': true,
          'min-h-[36px]': true,
          [fontClass]: true,
          'text-surface-title font-semibold break-all whitespace-break-spaces': true,
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          // reset styling
          'text-start bg-transparent p-0 m-0': true,
          '!cursor-default': true,
          // hover styling
          'hhover:bg-light-text-300/50': darkMode === false,
          'hhover:bg-dark-text-300/50': darkMode === true,
          // focus styling
          'focus-visible:ring-[2.5px] rounded-xl': true,
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          // allow users to select text in button
          'select-auto': true,
        }"
        :title="__('Change padlet title')"
        :aria-label="__('Change padlet title')"
        aria-describedby="title-button-description"
        data-testid="surfaceTitle"
        @click="handleTitleClick"
      >
        <span aria-hidden="true">{{ title || __('Untitled padlet') }}</span>
      </OzBaseButton>
    </template>
    <!-- 
      Always render this button, but make it invisible if not in inline editing mode.
      This is to keep the button in the DOM so that it does not cause a layout shift
      when switching between editing and non-editing mode.
     -->
    <OzPlainButton
      :class="[
        !isBeingEditedInline && 'invisible',
        'shrink-0',
        'h-9',
        'text-body-small',
        'ms-1',
        'px-2',
        'rounded-xl',
        'focus-visible:ring-2',
      ]"
      :inert="!isBeingEditedInline"
      :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
      :size-preset="OzPlainButtonSizePreset.Bare"
      :dark-mode="darkMode === true"
      :text="__('Done')"
      data-testid="surfaceWallTitleInlineInputDoneButton"
      @blur="finishEditingIfFocusTargetIsOutside"
      @click.stop="finishEditing"
    />
  </div>
</template>
