<script setup lang="ts">
import { __ } from '@@/bits/intl'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import { storeToRefs } from 'pinia'

defineProps<{
  darkMode: boolean
  fontClass: string
}>()

const surfaceStore = useSurfaceStore()
const { title, description } = storeToRefs(surfaceStore)

const surfaceSettingsStore = useSurfaceSettingsStore()
const { setIsTitleClicked, showSettingsPanel } = surfaceSettingsStore

const handleTitleClick = (): void => {
  setIsTitleClicked(true)
  showSettingsPanel(SettingsSubpanel.Main)
}
</script>

<template>
  <div>
    <!-- <h1 id="title-button-description" class="sr-only">{{ title }}</h1> -->
    <!-- After adding the px-2, I added a -ms-2 so that the title text is still aligned with the rest of the header. -->
    <OzBaseButton
      :class="{
        'mt-[2px]': true,
        '-ms-2': true,
        // 'py-0.5': true,
        'px-2': true,
        // 'min-h-[38px]': !description,
        'break-words whitespace-break-spaces': true,
        'text-dark-text-100': darkMode === false,
        'text-light-text-100': darkMode === true,
        // reset styling
        'text-start bg-transparent p-0 m-0': true,
        '!cursor-default': true,
        // hover styling
        // 'hhover:bg-light-text-300/50': darkMode === false,
        // 'hhover:bg-dark-text-300/50': darkMode === true,
        // focus styling
        // 'focus-visible:ring-[2.5px] rounded-xl': true,
        // 'ring-grape-500': darkMode === false,
        // 'ring-canary-500': darkMode === true,
        // allow users to select text in button
        'select-auto': true,
      }"
      :aria-label="__('Change padlet title')"
      aria-describedby="title-button-description"
      data-testid="surfaceTitle"
      @click="handleTitleClick"
    >
      <span
        aria-hidden="true"
        :class="{
          'text-surface-title font-semibold': true,
          [fontClass]: true,
        }"
        >{{ title || __('Untitled padlet') }}</span
      >
    </OzBaseButton>
  </div>
</template>
