<script setup lang="ts">
import { appLink } from '@@/bits/app_link'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import type { ResizeObserver } from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import { observeSizeChange } from '@@/bits/resize_observer'
import { checkIfUserIsInZoomCollaborateMode } from '@@/bits/zoom'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import OzPadletLogo, { OzPadletLogoColorScheme } from '@@/library/v4/components/OzPadletLogo.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import AppBarAccountAvatar from '@@/vuecomponents/AppBarAccountAvatar.vue'
import SurfaceGuestIdLabel from '@@/vuecomponents/SurfaceGuestIdLabel.vue'
import SurfaceOnboardingDemoPadletPulsatingWrapper from '@@/vuecomponents/SurfaceOnboardingDemoPadletPulsatingWrapper.vue'
import SurfaceTitleMobile from '@@/vuecomponents/SurfaceTitleMobile.vue'
import { useHandleFirstVerticalSwipe } from '@@/vuecomposables/useHandleFirstVerticalSwipe'
import { GroupByTypes } from '@padlet/arvo'
import { debounce } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

const rootEl = ref<HTMLDivElement>()
const surfaceTitle = ref<InstanceType<typeof SurfaceTitleMobile>>()
const surfaceNavbar = ref<HTMLDivElement>()

const surfaceStore = useSurfaceStore()
const {
  isEmbedded,
  isElectronApp,
  xHeader,
  user,
  isUserHostingZoomCollaboration,
  headerHeight,
  dir,
  headerColor,
  backgroundLuminance,
  colorScheme,
  isMap,
  homeUrl,
  isSectionBreakout,
  isReadOnly,
  wallAttributes,
  isFrozen,
  isGrid,
  canUseSections,
  skinPrimaryVariantColorAsRGB,
  isSkinColorAvailable,
  onboardingDemoButtonStyle,
} = storeToRefs(surfaceStore)
const { resizeHeader, checkAndSubscribeToPushNotifications } = surfaceStore

const { wishGroupBy } = storeToRefs(useSurfaceWishArrangementStore())

const surfacePermissionsStore = useSurfacePermissionsStore()
const { amIRegistered, canIAdminister } = storeToRefs(surfacePermissionsStore)

const surfaceSettingsStore = useSurfaceSettingsStore()
const { toggleSettingsPanel } = surfaceSettingsStore

const surfaceGuestStore = useSurfaceGuestStore()
const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

const { isContainerSmallerThanTabletPortrait } = storeToRefs(surfaceContainerSizeStore)

const resizeObserver = ref<ResizeObserver | null>(null)

// COLLAPSE
const isCollapsed = ref<boolean | null>(null)
function collapse(): void {
  if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) return
  isCollapsed.value = true
  updateHeaderHeight()
}
function toggleIsCollapsed() {
  if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) return
  isCollapsed.value = !isCollapsed.value
  updateHeaderHeight()
}

function updateHeaderHeight(): void {
  // Since the resize is debounced, we need to check
  // that these elements still exist.
  if (!surfaceNavbar.value || !surfaceTitle.value) return
  if (!isCollapsed.value) {
    nextTick(() => {
      if (!surfaceNavbar.value || !surfaceTitle.value) return
      resizeHeader({
        height: surfaceNavbar.value.getBoundingClientRect().height + surfaceTitle.value.$el.clientHeight,
      })
    })
  } else {
    resizeHeader({ height: 48 })
  }
}

// Obfuscate the app link
function openAppLink() {
  navigateTo(appLink)
}

// Collapse header on first vertical swipe for touch devices (only grid format w sections)
const isGridWithSections = computed(
  () => isGrid.value && canUseSections.value && wishGroupBy.value !== GroupByTypes.None,
)
if (isGridWithSections.value) {
  useHandleFirstVerticalSwipe(collapse)
}

// ZOOM
const isUserInZoomCollaborationMode = ref(false)
const isZoomCollaborationGuest = computed(
  () => isUserInZoomCollaborationMode.value === true && isUserHostingZoomCollaboration.value === false,
)
// END ZOOM

const hasDarkBackgroundLuminance = computed(() => {
  if (isMap.value) {
    return colorScheme.value !== 'light'
  }

  return backgroundLuminance.value !== 'light'
})

const realBackgroundLuminance = computed<'light' | 'dark'>(() => {
  if (isMap.value) {
    return colorScheme.value
  }
  return backgroundLuminance.value
})

onMounted(async () => {
  // Don't prompt for notifications if the user is not registered and the app is not the electron app
  if (amIRegistered.value && !isElectronApp.value) checkAndSubscribeToPushNotifications()
  updateHeaderHeight()
  if (rootEl.value != null) {
    resizeObserver.value = observeSizeChange(rootEl.value, debounce(updateHeaderHeight, 250))
  }
  isUserInZoomCollaborationMode.value = await checkIfUserIsInZoomCollaborateMode()
})

onBeforeUnmount(() => {
  resizeObserver.value?.disconnect()
})

defineExpose({
  collapse,
})
</script>

<template>
  <div
    id="surface-header"
    ref="rootEl"
    data-testid="surfaceHeaderMobile"
    :dir="dir"
    :class="{
      'transition-all ease-in-out duration-400': isCollapsed !== null,
      'app-bar': true,
      'surface-header': true,
      relative: true,
    }"
    :data-luminance="realBackgroundLuminance"
    :style="{ backgroundColor: headerColor, height: headerHeight + 'px' }"
    @click.self="toggleIsCollapsed"
    @keydown.space="toggleIsCollapsed"
  >
    <div ref="surfaceNavbar" @click.self="toggleIsCollapsed" @keydown.space="toggleIsCollapsed">
      <div
        :class="[
          'ps-4 pt-4.5 shrink-0',
          { 'animate-logo-enter': !isCollapsed && isCollapsed !== null, 'animate-logo-leave': isCollapsed },
        ]"
      >
        <OzPadletLogo
          v-if="xHeader && !isElectronApp && !isZoomCollaborationGuest"
          class="w-12 h-3"
          :dark-mode="hasDarkBackgroundLuminance"
          :color-scheme="OzPadletLogoColorScheme.Text300"
          :href="homeUrl"
          data-testid="padletLogo"
        />
      </div>

      <OzIconButton
        v-if="xHeader && !isElectronApp && !isEmbedded"
        :class="{
          'w-6': true,
          'shrink-0': true,
          'ms-2 mt-1': true,
          'transition-all': true,
          'animate-crane-enter': isCollapsed,
          'animate-crane-leave': !isCollapsed && isCollapsed !== null,
          absolute: true,
          'opacity-0': isCollapsed === null,
          'pointer-events-none': isCollapsed === null || !isCollapsed,
          'z-10': true,
          '!bg-transparent': isZoomCollaborationGuest,
        }"
        :size-preset="OzIconButtonSizePreset.H40px"
        :icon-size="24"
        :dark-mode="realBackgroundLuminance === 'dark'"
        :color-scheme="OzIconButtonColorScheme.SecondaryClear"
        icon-name="crane"
        :href="isZoomCollaborationGuest ? undefined : homeUrl"
        :label="__('Go to Padlet homepage')"
      />

      <div class="absolute flex shrink-0 flex-row end-2 top-1 z-10 gap-x-1.5 items-center">
        <OzContainedButton
          v-if="
            isAppUsing('openInAppBanner') &&
            !device.app &&
            (device.ios || device.android) &&
            isCollapsed !== true &&
            surfaceDemoPadletPanelStore.demoPadletPanelVisibility.showSurfaceOpenInAppButton
          "
          class="max-w-39 truncate"
          :style="isSkinColorAvailable ? { backgroundColor: skinPrimaryVariantColorAsRGB } : {}"
          :aria-label="__('Open in Padlet app')"
          :text="__('Open in app')"
          :color-scheme="OzContainedButtonColorScheme.Transparent"
          :size-preset="OzContainedButtonSizePreset.H24px"
          :dark-mode="realBackgroundLuminance === 'dark'"
          data-testid="surfaceOpenInAppButton"
          data-pepin-id="surface-header-mobile-open-in-app"
          data-pepin-trigger="click"
          @click="openAppLink"
        />
        <SurfaceOnboardingDemoPadletPulsatingWrapper
          v-if="surfaceDemoPadletPanelStore.demoPadletPanelVisibility.showModifiedSurfaceActionBarSettingsButton"
          :is-smaller-than-tablet-portrait="isContainerSmallerThanTabletPortrait"
          :has-square-shape="true"
        >
          <OzIconButton
            v-if="
              canIAdminister &&
              !isReadOnly &&
              !isFrozen &&
              surfaceDemoPadletPanelStore.demoPadletPanelVisibility.showSurfaceModifyGearIcon
            "
            :label="__('Modify')"
            icon-name="gear_outline"
            :icon-size="24"
            :disabled="isSectionBreakout"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="OzIconButtonColorScheme.SecondaryClear"
            :class="'text-light-text-100'"
            :style="onboardingDemoButtonStyle"
            :dark-mode="realBackgroundLuminance === 'dark'"
            data-testid="surfaceSettingsButton"
            @click="toggleSettingsPanel"
          />
        </SurfaceOnboardingDemoPadletPulsatingWrapper>
        <template v-else>
          <OzIconButton
            v-if="
              canIAdminister &&
              !isReadOnly &&
              !isFrozen &&
              surfaceDemoPadletPanelStore.demoPadletPanelVisibility.showSurfaceModifyGearIcon
            "
            :label="__('Modify')"
            icon-name="gear_outline"
            :icon-size="24"
            :disabled="isSectionBreakout"
            :size-preset="OzIconButtonSizePreset.H40px"
            :color-scheme="
              isSectionBreakout ? OzIconButtonColorScheme.Transparent : OzIconButtonColorScheme.SecondaryClear
            "
            :dark-mode="realBackgroundLuminance === 'dark'"
            data-testid="surfaceSettingsButton"
            @click="toggleSettingsPanel"
          />
        </template>
        <SurfaceGuestIdLabel v-if="surfaceGuestStore.shouldEnableAnonymousAttribution && !amIRegistered" />
        <AppBarAccountAvatar
          v-else
          :class="['ms-1.5', device.zoomApp && '!bg-transparent']"
          :user="user"
          :referrer-url="wallAttributes.links ? wallAttributes.links.show : undefined"
          :dark-mode="realBackgroundLuminance === 'dark'"
          :disabled="device.zoomApp"
        />
      </div>
    </div>
    <SurfaceTitleMobile
      ref="surfaceTitle"
      :class="{
        absolute: true,
        'transition-all duration-400 ease-in-out': true,
        'top-7.5': !isCollapsed && !isElectronApp,
        'top-0': isCollapsed,
      }"
      :is-collapsed="isCollapsed"
      @title-changed="updateHeaderHeight"
      @description-changed="updateHeaderHeight"
      @portrait-changed="updateHeaderHeight"
      @toggle-header="toggleIsCollapsed"
    />
  </div>
</template>

<style lang="scss" scoped>
.animate-logo-enter {
  animation: animation-logo-enter 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.animate-logo-leave {
  animation: animation-logo-leave 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  position: absolute;
  top: 0;
  pointer-events: none;
}
@keyframes animation-logo-enter {
  0% {
    transform: translate(0, -24px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-logo-leave {
  100% {
    transform: translate(0, -24px);
    opacity: 0;
  }
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.animate-crane-enter {
  animation: animation-crane-enter 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.animate-crane-leave {
  animation: animation-crane-leave 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  top: 0;
  pointer-events: none;
}
@keyframes animation-crane-enter {
  0% {
    transform: translate(0, 12px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes animation-crane-leave {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 12px);
    opacity: 0;
  }
}
</style>
