<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { tailwindSurfaceFontClass } from '@@/bits/font'
import { __ } from '@@/bits/intl'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceOnboardingDemoPadletAnimationsStore } from '@@/pinia/surface_onboarding_demo_padlet_animations_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceQrCodeStore } from '@@/pinia/surface_qr_code'
import SurfaceBuilder from '@@/vuecomponents/SurfaceBuilder.vue'
import SurfaceTitleDescription from '@@/vuecomponents/SurfaceTitleDescription.vue'
import ProcessedImage from '@@/vuecomponents/processed_image.vue'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    isCollapsed?: boolean | null
  }>(),
  {
    isCollapsed: null,
  },
)

const emit = defineEmits(['title-changed', 'description-changed', 'portrait-changed', 'toggle-header'])

const surfaceStore = useSurfaceStore()
const { title, description, portrait, backgroundLuminance, colorScheme, fontId, isMap } = storeToRefs(surfaceStore)

const { pinnedQrCodeUrl, xPinnedQrCode } = storeToRefs(useSurfaceQrCodeStore())

const PORTRAIT_SIZE = 32

const titleEl = ref<HTMLHeadingElement>()
const titlePlaceholderForCaretPosition = ref<HTMLHeadingElement>()
const titleOneLineOffsetHeight = computed(() => {
  const titlePlaceholderEl = titlePlaceholderForCaretPosition.value
  if (titlePlaceholderEl) {
    const computedStyle = window.getComputedStyle(titlePlaceholderEl)
    return parseFloat(computedStyle.lineHeight)
  }
  return 26
})

const isLongTitle = ref<boolean>(false)

const fontClass = computed<string>(() => {
  return tailwindSurfaceFontClass(fontId.value)
})

const xPortrait = computed<boolean>(() => xPinnedQrCode.value || !!portrait.value)

const updateCaretPosition = (): void => {
  const titlePlaceholderForCaretPositionEl = titlePlaceholderForCaretPosition.value
  if (!titleEl.value || !titlePlaceholderForCaretPositionEl) {
    return
  }
  if (titleEl.value.offsetHeight > titleOneLineOffsetHeight.value) {
    isLongTitle.value = true
  } else {
    isLongTitle.value = false
  }
  titlePlaceholderForCaretPositionEl.innerText = ''
  nextTick(() => {
    if (!titlePlaceholderForCaretPositionEl) {
      return
    }
    if (!props.isCollapsed) {
      const titleWords = title.value.split(' ')
      let titleFirstLine = ''
      titleWords.every((word): boolean => {
        const nextTitleFirstLine = !titleFirstLine ? word : `${titleFirstLine} ${word}`
        titlePlaceholderForCaretPositionEl.innerText = nextTitleFirstLine
        if (titlePlaceholderForCaretPositionEl.offsetHeight > titleOneLineOffsetHeight.value) {
          // if titleFirstLine is not empty, then we keep it, otherwise we keep the original title
          titlePlaceholderForCaretPositionEl.innerText = titleFirstLine || title.value
          return false
        }
        titleFirstLine = nextTitleFirstLine
        return true
      })
    } else {
      titlePlaceholderForCaretPositionEl.innerText = title.value
    }
  })
}

watch(title, (): void => {
  emit('title-changed')
})

watch(description, (): void => {
  emit('description-changed')
})

watch(portrait, (): void => {
  emit('portrait-changed')
})

watch([() => props.isCollapsed, title, portrait], updateCaretPosition)

onMounted((): void => {
  updateCaretPosition()
})

// Demo padlet logic

const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

const { hasClickedTopicText, xTopicText, pulsateTopicText, xTopicEmojiBackground, xTopicEmoji } = storeToRefs(
  useSurfaceOnboardingDemoPadletAnimationsStore(),
)
const { startTopicAnimation } = useSurfaceOnboardingDemoPadletAnimationsStore()

const surfaceTitleRef = ref<HTMLElement | null>(null)
const surfaceDescriptionComponent = ref<typeof SurfaceTitleDescription | null>(null)

const realBackgroundLuminance = computed(() => {
  if (isMap.value) {
    return colorScheme.value
  }
  return backgroundLuminance.value
})

const handleTitleAndDescriptionClick = (): void => {
  if (!hasClickedTopicText.value) {
    trackEvent('Demo padlet onboarding', 'Clicked on title and description')
    startTopicAnimation()
  }
}
</script>

<template>
  <div
    v-if="!surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop"
    :class="{
      'surface-title-root': true,
      'transition-all duration-400 ease-in-out': true,
      'flex justify-start': true,
      'border-box': true,
      'overflow-visible': true,
      'w-full': true,
      'long-title': isLongTitle,
      'description-present': !!description,
      'portrait-present': xPortrait,
    }"
  >
    <!-- portrait -->

    <div
      :class="{
        'flex shrink-0 items-center justify-center box-content': true,
        'w-10': !xPortrait,
        'w-8 h-8 ps-6 pe-4 pt-5.75': xPortrait,
        'animate-portrait-enter': !isCollapsed && isCollapsed !== null,
        'animate-portrait-leave': isCollapsed,
        'absolute top-0': true,
      }"
      @keydown.enter="$emit('toggle-header')"
      @click.stop="$emit('toggle-header')"
    >
      <ImageThumbnail
        v-if="xPinnedQrCode"
        :alt="__('Padlet QR code')"
        :title="__('Padlet QR code')"
        :src="pinnedQrCodeUrl"
        :original-image-height="1280"
        :original-image-width="1280"
        :width="PORTRAIT_SIZE"
        :height="PORTRAIT_SIZE"
      />
      <ProcessedImage
        v-else-if="portrait"
        class="rounded-lg"
        :src="portrait"
        :width="PORTRAIT_SIZE"
        :height="PORTRAIT_SIZE"
        :transforms="{ preset: 'icon', width: PORTRAIT_SIZE, height: PORTRAIT_SIZE }"
      />
    </div>

    <!-- on mobile, portrait is position fixed for easier animation, this is the space that the portrait would take -->
    <div
      :class="{
        'shrink-0': true,
        'w-5 tablet-portrait:w-10': !xPortrait,
        'w-18': xPortrait,
        absolute: isCollapsed,
      }"
    />

    <!-- builder, title and description -->
    <div
      :class="{
        'w-full': true,
        'mt-3.5': !isCollapsed && !description,
        'mt-1.5': !isCollapsed && description,
      }"
    >
      <!-- builder -->

      <SurfaceBuilder
        :class="{
          'text-body-extra-small': true,
          'animate-builder-enter': !isCollapsed && isCollapsed !== null,
          'animate-builder-leave': isCollapsed,
        }"
      />

      <!-- title and description; also tapzone for compact mode -->
      <div class="flex flex-col" @keydown.enter="$emit('toggle-header')" @click.stop="$emit('toggle-header')">
        <!-- title clone 0: This is the real title. On mobile layout this animates upwards when compacted -->
        <div
          :class="{
            'absolute flex ': true,
            'animate-title-collapse': isCollapsed,
            'animate-title-uncollapse': !isCollapsed && isCollapsed !== null,
            'my-3 ms-13.5': isCollapsed,
            'mt-0 me-4': !isCollapsed,
            'max-w-[calc(100%-160px)]': isCollapsed,
          }"
        >
          <h1
            :class="{
              'w-full': true,
              'pe-2': isCollapsed && isLongTitle,
              [fontClass]: true,
              'whitespace-break-spaces': !isCollapsed,
              'whitespace-nowrap': isCollapsed,
              'text-ellipsis': isCollapsed,
              'overflow-hidden': isCollapsed,
              'text-heading-3': !isCollapsed,
              'text-heading-4 leading-6': isCollapsed,
              'font-semibold break-words': true,
              'text-dark-text-100': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
              'text-light-text-100': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
            }"
            data-testid="surfaceTitle"
            v-text="title || __('Untitled padlet')"
          />

          <!-- title clone 1: only on mobile; for titles that is more than 1 line to have their 2nd line onwards fading in and out   -->
          <h1
            :class="{
              absolute: true,
              'pe-2': isCollapsed,
              'w-full': true,
              [fontClass]: true,
              'whitespace-break-spaces': true,
              'text-heading-3': !isCollapsed,
              'text-heading-4 leading-6': isCollapsed,
              'animate-title-lines-fade-out-collapse': isCollapsed,
              'animate-title-lines-fade-in-uncollapse': !isCollapsed && isCollapsed !== null,
              'font-semibold break-words': true,
              'text-dark-text-100': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
              'text-light-text-100': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
            }"
            @keydown.enter="$emit('toggle-header')"
            @click.stop="$emit('toggle-header')"
            v-text="title || __('Untitled padlet')"
          />
        </div>

        <!-- title clone 3: only on mobile; this will be invisible and will be used to push the header down in order to compute the correct height. -->
        <h1
          ref="titleEl"
          :class="{
            [fontClass]: true,
            'whitespace-break-spaces': true,
            'mt-6.5': isCollapsed,
            'me-4': !isCollapsed && !isLongTitle,
            'me-7': !isCollapsed && isLongTitle,
            'text-heading-3': true,
            invisible: true,
            'font-semibold break-words': true,
            'text-dark-text-100': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
            'text-light-text-100': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
          }"
          @keydown.enter="$emit('toggle-header')"
          @click.stop="$emit('toggle-header')"
          v-text="title || __('Untitled padlet')"
        />
        <div
          :class="{
            'absolute shrink-0 flex flex-row items-start': true,
            'w-full': isCollapsed,
          }"
        >
          <!-- eslint-disable vue-a11y/heading-has-content -->
          <!-- eslint-disable vuejs-accessibility/heading-has-content -->
          <!-- title clone 4: on mobile: for positioning the caret at the end of the first line of text -->
          <h1
            ref="titlePlaceholderForCaretPosition"
            :class="{
              [fontClass]: true,
              'my-3 ms-13.5': isCollapsed,
              'max-w-[calc(100%-180px)]': isCollapsed,
              'whitespace-break-spaces': !isCollapsed,
              'whitespace-nowrap': isCollapsed,
              'text-ellipsis': isCollapsed,
              'overflow-hidden': isCollapsed,
              'text-heading-3': !isCollapsed,
              'text-heading-4 leading-6': isCollapsed,
              invisible: true,
              'tablet-landscape:text-surface-title font-semibold break-words': true,
              'text-dark-text-100': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
              'text-light-text-100': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
            }"
            @keydown.enter="$emit('toggle-header')"
            @click.stop="$emit('toggle-header')"
          ></h1>
          <!-- eslint-enable vue-a11y/heading-has-content -->
          <!-- eslint-enable vuejs-accessibility/heading-has-content -->
          <div
            :class="{
              'h-6.5': !isCollapsed,
              'h-12': isCollapsed,
              'flex items-center': true,
              'ms-2': !isCollapsed,
            }"
          >
            <OzBaseButton
              :class="{
                'h-fit w-fit p-0 m-0 bg-transparent': true,
                'focus-visible:ring rounded': true,
                'focus-visible:ring-canary-500': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
                'focus-visible:ring-grape-500': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
                'animate-title-caret-collapse ': isCollapsed,
                'animate-title-caret-uncollapse': !isCollapsed && isCollapsed !== null,
                'caret-just-mounted': isCollapsed === null,
              }"
              :aria-label="isCollapsed ? __('Expand padlet header') : __('Collapse padlet header')"
              @keydown.enter="$emit('toggle-header')"
              @click.stop="$emit('toggle-header')"
            >
              <OzIcon
                name="chevron_right_center"
                :size="12"
                :class="{
                  'text-dark-text-300': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
                  'text-light-text-300': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
                }"
              />
            </OzBaseButton>
          </div>
        </div>
        <SurfaceTitleDescription
          v-if="description"
          :text="description"
          :font-id="fontId"
          :color-scheme="isMap ? colorScheme : backgroundLuminance"
          :class="[
            'mt-0',
            'me-4',
            {
              'animate-description-enter': !isCollapsed && isCollapsed !== null,
              'animate-description-leave': isCollapsed,
            },
          ]"
        />
      </div>

      <!-- instead of padding we use a fixed height block so that the header height can be computed correctly -->
      <div
        :class="{
          'h-3': !isCollapsed,
        }"
      />
    </div>
  </div>
  <!-- New user onboarding: Demo padlet panel -->
  <div
    v-else
    :class="{
      'surface-title-root': true,
      'transition-all duration-400 ease-in-out': true,
      'flex justify-start': true,
      'border-box': true,
      'overflow-visible': true,
      'w-full': true,
      'long-title': isLongTitle,
      'description-present': !!description,
      'portrait-present': xPortrait,
    }"
  >
    <!-- portrait -->

    <div
      :class="{
        'flex shrink-0 items-center justify-center box-content': true,
        'w-10': !xPortrait,
        'w-8 h-8 ps-6 pe-4 pt-5.75': xPortrait,
        'animate-portrait-enter': !isCollapsed && isCollapsed !== null,
        'animate-portrait-leave': isCollapsed,
        'absolute top-0': true,
      }"
    >
      <ImageThumbnail
        v-if="xPinnedQrCode"
        :alt="__('Padlet QR code')"
        :title="__('Padlet QR code')"
        :src="pinnedQrCodeUrl"
        :original-image-height="1280"
        :original-image-width="1280"
        :width="PORTRAIT_SIZE"
        :height="PORTRAIT_SIZE"
      />
      <ProcessedImage
        v-else-if="portrait"
        :style="{
          backgroundColor: xTopicEmojiBackground ? 'rgba(111, 28, 156, 0.15)' : '',
        }"
        :class="[
          'rounded-lg',
          'p-1 transition-all duration-500 ease-in-out',
          'opacity-0' && !xTopicEmoji,
          'opacity-100' && xTopicEmoji,
        ]"
        :src="portrait"
        :width="PORTRAIT_SIZE"
        :height="PORTRAIT_SIZE"
        :transforms="{ preset: 'icon', width: PORTRAIT_SIZE, height: PORTRAIT_SIZE }"
      />
    </div>

    <!-- on mobile, portrait is position fixed for easier animation, this is the space that the portrait would take -->
    <div
      :class="{
        'shrink-0': true,
        'w-5 tablet-portrait:w-10': !xPortrait,
        'w-18': xPortrait,
        absolute: isCollapsed,
      }"
    />

    <!-- builder, title and description -->
    <div
      :class="{
        'w-full': true,
        'mt-3.5': !isCollapsed && !description,
        'mt-1.5': !isCollapsed && description,
      }"
    >
      <!-- builder -->

      <SurfaceBuilder
        :class="{
          'text-body-extra-small': true,
        }"
      />
      <div
        :class="[
          'inline-block px-2 -mx-2 relative duration-500 ease-in-out transition-all rounded-xl overflow-hidden',
          pulsateTopicText && 'animate-pulse hover:opacity-75 cursor-pointer',
        ]"
        :style="{
          backgroundColor: pulsateTopicText ? 'rgba(111, 28, 156, 0.15)' : '',
        }"
        @click="handleTitleAndDescriptionClick"
        @keydown="handleTitleAndDescriptionClick"
      >
        <!-- Background Container -->
        <div
          :class="[
            'absolute z-10 inset-0 inline-block px-2 transition-all -mx-2 duration-500 ease-in-out rounded-xl overflow-hidden',
            pulsateTopicText && 'animate-pulse',
          ]"
        ></div>
        <!-- title and description; also tapzone for compact mode -->
        <div class="flex flex-col">
          <!-- title clone 0: This is the real title. On mobile layout this animates upwards when compacted -->
          <div
            ref="surfaceTitleRef"
            :class="{
              'flex ': true,
              'animate-title-collapse': isCollapsed,
              'animate-title-uncollapse': !isCollapsed && isCollapsed !== null,
              'mt-0 me-4': true,
              'max-w-[calc(100%-160px)]': isCollapsed,
              'ps-2 -ms-2 pe-10 rounded-xl inline-block z-20 transition-colors duration-500 ease-in-out': true,
              'text-transparent': !xTopicText,
            }"
          >
            <h1
              :class="{
                'w-full': true,
                'pe-2': isCollapsed && isLongTitle,
                [fontClass]: true,
                'whitespace-break-spaces': !isCollapsed,
                'whitespace-nowrap': isCollapsed,
                'text-ellipsis': isCollapsed,
                'overflow-hidden': isCollapsed,
                'text-heading-3': !isCollapsed,
                'text-heading-4 leading-6': isCollapsed,
                'font-semibold break-words': true,
                'text-dark-text-100': isMap ? colorScheme === 'light' : backgroundLuminance === 'light',
                'text-light-text-100': isMap ? colorScheme === 'dark' : backgroundLuminance === 'dark',
                'text-transparent': !xTopicText,
                'transition-colors duration-500 ease-in-out': true,
              }"
              v-text="title || __('Untitled padlet')"
            />
          </div>

          <div
            :class="{
              'absolute shrink-0 flex flex-row items-start': true,
              'w-full': isCollapsed,
            }"
          >
            <!-- eslint-enable vue-a11y/heading-has-content -->
            <!-- eslint-enable vuejs-accessibility/heading-has-content -->
          </div>
          <SurfaceTitleDescription
            v-if="description"
            ref="surfaceDescriptionComponent"
            :text="description"
            :font-id="fontId"
            :color-scheme="isMap ? colorScheme : backgroundLuminance"
            :class="[
              'pb-7 ps-2 -ms-2 rounded-xl overflow-hidden block z-20',
              'transition-colors duration-500 ease-in-out',

              !xTopicText && 'text-transparent',
              'mt-0',
              'me-4',
              xTopicText && realBackgroundLuminance === 'light' && 'text-dark-text-200',
              xTopicText && realBackgroundLuminance === 'dark' && 'text-light-text-200',
            ]"
          />
        </div>
      </div>

      <!-- instead of padding we use a fixed height block so that the header height can be computed correctly -->
      <div
        :class="{
          'h-3': !isCollapsed,
        }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fade-out {
  transition: all 400ms cubic-bezier(0.39, 0.575, 0.565, 1);
  overflow: visible;
}
.faded-out {
  opacity: 0;
  height: 0;
}

.surface-title-root {
  --builder-offset-top: 44px;
  --title-offset-top: 20px;
  --builder-height: 17px;
  &.description-present {
    --builder-offset-top: 36px;
    --title-offset-top: 16px;
  }
  --builder-offset-start: 20px;
  --title-offset-left: 32px;
  --description-offset-left: 20px;
  --description-travel-x: 14px;
  --caret-travel-x: 0px;
  [dir='rtl'] & {
    --title-offset-left: -12px;
    --description-offset-left: -40px;
    --description-travel-x: -14px;
    --caret-travel-x: 0px;
  }
  &.long-title {
    --caret-travel-x: 80px;
    [dir='rtl'] & {
      --caret-travel-x: -80px;
    }
  }
  &.portrait-present {
    --builder-offset-start: 72px;
    --title-offset-left: -18px;
    --description-offset-left: 72px;
    --description-travel-x: -18px;
    --caret-travel-x: 20px;
    [dir='rtl'] & {
      --title-offset-left: 28px;
      --description-offset-left: -72px;
      --description-travel-x: 18px;
      --caret-travel-x: -20px;
    }
    &.long-title {
      --caret-travel-x: 88px;
    }
  }
  --caret-default-rotation: 90deg;
  --caret-collapsed-rotation: 0deg;
  [dir='rtl'] & {
    --caret-default-rotation: 90deg;
    --caret-collapsed-rotation: 180deg;
  }
}

.animate-portrait-enter {
  animation: animation-portrait-enter 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-portrait-leave {
  animation: animation-portrait-leave 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-portrait-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation-portrait-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-title-collapse {
  animation: animation-title-collapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-title-collapse {
  0% {
    transform-origin: center left;
    transform: translate(calc(-1 * var(--title-offset-left)), var(--title-offset-top)) scale(1.17647059);
    font-size: 17px;
  }
  100% {
    transform-origin: center left;
    transform: translate(0, 0) scale(1);
    font-size: 17px;
  }
}
.animate-title-uncollapse {
  animation: animation-title-uncollapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-title-uncollapse {
  0% {
    transform-origin: center left;
    transform: translate(var(--title-offset-left), calc(-1 * var(--title-offset-top))) scale(0.85);
    font-size: 20px;
    height: 26px;
    overflow: hidden;
  }
  100% {
    transform-origin: center left;
    transform: translate(0, 0) scale(1);
    font-size: 20px;
    height: 26px;
    overflow: visible;
  }
}

.animate-title-lines-fade-in-uncollapse {
  animation: animation-title-lines-fade-in-uncollapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
@keyframes animation-title-lines-fade-in-uncollapse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-title-lines-fade-out-collapse {
  animation: animation-title-lines-fade-out-collapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
@keyframes animation-title-lines-fade-out-collapse {
  0% {
    transform-origin: center left;
    opacity: 1;
  }

  100% {
    transform-origin: center left;
    opacity: 0;
  }
}

.caret-just-mounted {
  transform: rotate(var(--caret-default-rotation));
}

.animate-short-title-caret-collapse {
  animation: animation-short-title-caret-collapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-short-title-caret-collapse {
  0% {
    transform: rotate(var(--caret-default-rotation)) scale(0.85);
    margin-inline-start: 0.5rem;
  }
  100% {
    transform: rotate(var(--caret-collapsed-rotation)) scale(1);
    margin-inline-start: 1rem;
  }
}
.animate-short-title-caret-uncollapse {
  animation: animation-short-title-caret-uncollapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-short-title-caret-uncollapse {
  0% {
    transform: rotate(var(--caret-collapsed-rotation)) scale(1.17647059);
    margin-inline-start: 1rem;
  }
  100% {
    transform: rotate(var(--caret-default-rotation)) scale(1);
    margin-inline-start: 0.5rem;
  }
}

.animate-title-caret-collapse {
  animation: animation-title-caret-collapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-title-caret-collapse {
  0% {
    transform: translate(var(--caret-travel-x), var(--title-offset-top)) rotate(var(--caret-default-rotation));
    margin-inline-start: 0.5rem;
  }
  100% {
    transform: translate(0, 0) rotate(var(--caret-collapsed-rotation));
    margin-inline-start: 1rem;
  }
}
.animate-title-caret-uncollapse {
  animation: animation-title-caret-uncollapse 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-title-caret-uncollapse {
  0% {
    transform: translate(calc(-1 * var(--caret-travel-x)), calc(-1 * var(--title-offset-top)))
      rotate(var(--caret-collapsed-rotation));
    margin-inline-start: 1rem;
  }
  100% {
    transform: translate(0, 0) rotate(var(--caret-default-rotation));
    margin-inline-start: 0.5rem;
  }
}
.animate-builder-enter {
  overflow: visible;
  animation: animation-builder-enter 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-builder-leave {
  position: fixed;
  left: var(--builder-offset-start);
  [dir='rtl'] & {
    left: auto;
    right: var(--builder-offset-start);
  }
  top: var(--builder-offset-top);
  overflow: visible;
  animation: animation-builder-leave 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes animation-builder-enter {
  0% {
    opacity: 0;
    transform: translate(0, var(--builder-offset-top));
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes animation-builder-leave {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.animate-description-enter {
  animation: animation-description-enter 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.animate-description-leave {
  animation: animation-description-leave 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  transform: translate(var(--description-offset-left), 0px);
}

@keyframes animation-description-enter {
  0% {
    opacity: 0;
    transform: translate(var(--description-travel-x), -26px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes animation-description-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
